.mkit-custom {
  color: black;
}

tr.finding div,
tr.finding-fail div,
.all-resources-button,
.modal-close {
  cursor: pointer;
}

tr.finding:hover {
  background-color: #f9fafb;
}

tr.finding:hover svg {
  color: #6875f5;
}

.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-body code {
  font-size: 0.75rem;
  color: #2c7be5;
}

/* Octo Corner - https://github.com/tholman/github-corners */
.github-corner svg {
  position: absolute;
  top: 0;
  border: 0;
  right: 0;
}

.github-corner .octo-arm {
  transform-origin: 130px 106px;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}
